<template>
  <div>
    <b-card title="Ekle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="eduCalendar.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Eğitim Tarihi"
        label-for="edate"
      >
        <b-form-datepicker
          id="edate"
          v-model="eduCalendar.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="eduCalendar.content"
          placeholder="Metin"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="eduCalendar.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormDatepicker,
  BButton, BFormRadio, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BFormRadio,
    BRow,
    BCol,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    eduCalendar() {
      return this.$store.getters['eduCalendar/getEduCalendar']
    },
    saveStatus() {
      return this.$store.getters['eduCalendar/getEduCalendarSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getEduCalendar()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.notice.submitStatus = false
    },
  },
  beforeCreate() {
    this.$store.dispatch('eduCalendar/eduCalendarReset')
  },
  created() {
    this.getEduCalendar()
  },
  methods: {
    getEduCalendar() {
      this.$store.dispatch('eduCalendar/eduCalendarView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.eduCalendar.submitStatus = true
      this.$store.dispatch('eduCalendar/eduCalendarSave', this.eduCalendar)
    },
  },
}
</script>
